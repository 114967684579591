:root {
    --primary-bg-color: #f2f2f2;
    --secondary-bg-color: white;
    --primary-accent-color: #72a7b6;
    --primary-caution-color: rgb(212, 0, 0);
    --primary-button-color: #72a7b6;
    --primary-button-hover-color: #5f8b97;
    --invalid-button-color: rgb(155, 155, 155);
    --white-button-color: white;
    --white-button-hover-color: rgb(246, 246, 246);
    --primary-text-color: #262626;
    --secondary-text-color: white;
    --tertiary-text-color: #787878;
    --tag-new-color: #b63c3c;
    --border-radius-heavy: 16px;
    --border-radius-middle: 12px;
    --border-radius-light: 8px;
    --border-radius-too-light: 4px;
    --border-radius-tag: 4px;
    --border-radius-tag-icon: 4px 0 0 4px;
    --border-radius-tag-text: 0 4px 4px 0;
    --primary-box-shadow: 0 0 12px rgba(0, 136, 204, 0.2);
    --primary-box-shadow-inset: 0 0 4px rgba(0, 136, 204, 0.2) inset;
    --hover-foreground-color: rgba(0, 0, 0, 0.10);
    --border-color: #d8d8d8;
    --padding-heavy: 48px;
    --padding-middle: 24px;
    --padding-light: 12px;
    --padding-too-light: 8px;
    --padding-tag-middle: 4px;
    --padding-tag-light: 2px;
    --primary-input-height: 40px;
    --primary-input-light-height: 28px;
    --margin-too-heavy: 64px;
    --margin-heavy: 48px;
    --margin-middle: 24px;
    --margin-light: 12px;
    --margin-too-light: 8px;
    --gap-too-heavy: 36px;
    --gap-heavy: 24px;
    --gap-middle: 16px;
    --gap-light: 8px;
    --gap-too-light: 4px;
    --font-size-too-light: 12px;
    --font-size-light: 14px;
    --font-size-middle: 16px;
    --font-size-heavier: 18px;
    --font-size-heavy: 20px;
    --font-size-too-heavy: 24px;
    --user-icon-size: 28px;
    --logo-size: 28px;
    --line-height-middle: 28px;
}

* {
    color: var(--primary-text-color);
    font-size: var(--font-size-middle);
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    background: transparent;
    box-sizing: border-box;
}

body {
    background-color: var(--primary-bg-color);
    font-family: 游ゴシック体, 'Yu Gothic', YuGothic, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
    min-height: 100vh;
    min-width: 360px;
}

main {
    min-height: 100vh;
}

textarea {
    resize: none;
}

li {
    list-style: none;
}

a {
    cursor: pointer;
    text-decoration: none;
}

button, input[type='button'] {
    cursor: pointer;
}

b {
    font-weight: bold;
}

::selection {
    background-color: var(--primary-accent-color);
}

.marginBottomMiddle {
    margin-bottom: var(--margin-middle);
}

.marginTopMiddle {
    margin-top: var(--margin-middle);
}

.fontSizeHeavy {
    font-size: var(--font-size-heavy);
}

.primaryAccentColor {
    color: var(--primary-accent-color);
}

.primaryMain {
    padding-top: var(--margin-middle);
    padding-bottom: var(--margin-middle);
}

.mainWithSideBarLR, .mainWithSideBarR {
    margin: 0 var(--margin-light);
    display: flex;
    gap: var(--gap-middle);
    justify-content: center;
}

.sideBar {
    display: flex;
    flex-direction: column;
    gap: var(--gap-middle);
    width: 260px;
}

.secondaryBgColor {
    background-color: var(--secondary-bg-color);
}

.transparentBgColor {
    background-color: transparent;
}

.sectionWrapper {
    display: flex;
    flex-direction: column;
    gap: var(--gap-too-heavy);
    max-width: 1000px;
    width: 80%;
}

.mainWithSideBarLR .sectionWrapper, .mainWithSideBarR .sectionWrapper {
    flex: 1;
}

.mainNoSideBar .sectionWrapper {
    margin: 0 auto;
}

.primarySection {
    align-items: center;
    background-color: var(--secondary-bg-color);
    border-radius: var(--border-radius-light);
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: var(--padding-heavy);
    width: 100%;
}

.secondarySection {
    align-items: center;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: var(--padding-light);
    width: 100%;
}

.cautionText {
    color: red;
    font-weight: bold;
    font-size: var(--font-size-light);
}

.primaryButton {
    background-color: var(--primary-button-color);
    border-radius: var(--border-radius-light);
    color: var(--secondary-text-color);
    display: inline-block;
    font-weight: bold;
    line-height: var(--primary-input-height);
    height: var(--primary-input-height);
    padding-left: var(--padding-light);
    padding-right: var(--padding-light);
}

.primaryButton:hover {
    background-color: var(--primary-button-hover-color);
}

.primaryInput, .primarySelect {
    background-color: white;
    border-radius: var(--border-radius-light);
    border: solid 1px var(--border-color);
    height: var(--primary-input-height);
    padding-left: var(--padding-light);
    padding-right: var(--padding-light);
}

.primaryInput:focus, .primarySelect:focus {
    border: solid 1px var(--primary-accent-color);
}

.primaryTextArea {
    background-color: white;
    border-radius: var(--border-radius-light);
    border: solid 1px var(--border-color);
    padding: var(--padding-light);
}

.primaryInput:focus, .primarySelect:focus, .primaryTextArea:focus {
    border: solid 1px var(--primary-accent-color);
}

.whiteButton {
    background-color: var(--white-button-color);
    border-radius: var(--border-radius-light);
    color: var(--primary-text-color);
    font-weight: bold;
    height: var(--primary-input-height);
    line-height: var(--primary-input-height);
    padding-left: var(--padding-light);
    padding-right: var(--padding-light);
}

.whiteButton:hover {
    background-color: var(--white-button-hover-color);
}

.underbarButton {
    border-bottom: solid 1px #262626;
}

.underbarButton:hover {
    border-bottom: solid 1px var(--primary-accent-color);
}

.tagIcon {
    border: solid 1px var(--border-color);
    border-radius: var(--border-radius-tag-icon);
    border-right: none;
    font-size: var(--font-size-light);
    padding: var(--padding-too-light);
}

.tagText {
    border: solid 1px var(--border-color);
    border-radius: var(--border-radius-tag-text);
    font-size: var(--font-size-light);
    padding: var(--padding-too-light);
}

.tag {
    border: solid 1px var(--border-color);
    border-radius: var(--border-radius-tag);
    font-size: var(--font-size-light);
    font-weight: bold;
    padding: var(--padding-too-light);
}

.tagPrimaryColor {
    align-items: center;
    border: 1px solid var(--primary-accent-color);
    border-radius: 14px;
    color: var(--primary-accent-color);
    display: flex;
    font-size: var(--font-size-too-light);
    font-weight: bold;
    gap: var(--gap-too-light);
    height: 28px;
    padding: 0 var(--padding-too-light);
}

.tagPrimaryColor svg path {
    color: var(--primary-accent-color);
}

.userIcon {
    border-radius: 50%;
    height: var(--user-icon-size);
    width: var(--user-icon-size);
}

.primaryH1 {
    font-weight: bold;
    font-size: var(--font-size-too-heavy);
}

/*使用時には適切に上下marginを設定すること*/
.primaryH2 {
    background-color: var(--primary-accent-color);
    color: var(--secondary-text-color);
    font-size: var(--font-size-too-heavy);
    font-weight: bold;
    line-height: 56px;
    padding: 0 var(--padding-light);
    position: relative;
    white-space: pre-wrap;
}

.primaryH2::before {
    background-color: var(--primary-accent-color);
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    top: -4px;
    width: 100%;
}

.primaryH2::after {
    background-color: var(--primary-accent-color);
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    bottom: -4px;
    width: 100%;
}

.primaryH3 {
    border-bottom: solid 2px var(--primary-accent-color);
    font-size: var(--font-size-heavy);
    line-height: 40px;
    padding: 0 var(--padding-light);
    white-space: pre-wrap;
}

.primaryH4 {
    border-bottom: solid 2px var(--primary-accent-color);
    font-size: var(--font-size-heavier);
    line-height: 40px;
    padding: 0 var(--padding-tag-middle);
    padding-bottom: var(--padding-tag-middle);
    white-space: pre-wrap;
}

.primaryP {
    line-height: 32px;
    white-space: pre-wrap;
}

.primaryA:hover {
    color: var(--primary-accent-color);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primary-bg-color);
    z-index: 9999;
    display: none;
    justify-content: center;
    align-items: center;
}

.active {
    display: flex;
}

.tabContainer {
    display: flex;
    flex-wrap: nowrap;
    gap: var(--gap-light);
    justify-content: center;
    border-bottom: solid 1px var(--primary-accent-color);
    padding: 0 var(--padding-too-light);
}

.tabItem {
    position: relative;
    border: solid 1px var(--primary-accent-color);
    border-radius: var(--border-radius-light) var(--border-radius-light) 0 0;
    cursor: pointer;
    font-weight: bold;
    text-align: center;
    height: 40px;
    line-height: 40px;
    width: 100px;
    border-bottom: none;
}

.tabItemActive {
    background-color: var(--primary-accent-color);
    color: white;
    font-weight: bold;
}

.tabInverseContainer {
    display: flex;
    flex-wrap: nowrap;
    gap: var(--gap-light);
    justify-content: center;
    border-top: solid 1px var(--primary-accent-color);
    margin-top: var(--margin-light);
}

.tabItemInverse {
    position: relative; 
    border: solid 1px var(--primary-accent-color);
    border-top: none;
    border-radius: 0 0 var(--border-radius-light) var(--border-radius-light);
    cursor: pointer;
    font-weight: bold;
    text-align: center;
    height: 40px;
    line-height: 40px;
    width: 100px;
}

.successMessage {
    color: green;
    margin-bottom: var(--margin-light);
}

.errorMessage {
    color: red;
    font-weight: bold;
    font-size: var(--font-size-light);
    line-height: var(--line-height-middle);
}

.primaryTableWrapper {
    overflow-x: scroll;
    width: 100%;
    margin-top: var(--margin-middle);
    /*スクロールバー非表示（IE・Edge）*/
    -ms-overflow-style: none;
    /*スクロールバー非表示（Firefox）*/
    scrollbar-width: none;
}

/*スクロールバー非表示（Chrome・Safari）*/
.primaryTableWrapper::-webkit-scrollbar {
    display:none;
}

.primaryTable {
    border: 1px solid var(--border-color);
    border-collapse: collapse;
    margin: 0 auto;
}

.primaryTrHeader {
    background-color: var(--primary-accent-color);
}

.primaryTrHeader .primaryTh {
    color: white;
}

.primaryTh, .primaryTd {
    border-left: 1px solid var(--border-color);
}

.primaryTr:nth-of-type(odd) {
    background-color: white;
}

.primaryTr:nth-of-type(even) {
    background-color: #ededed;
}

.primaryTh {
    font-size: bold;
    padding: var(--padding-light);
    text-align: left;
    max-width: 200px;
    min-height: 50px;
    min-width: 100px;
}

.primaryTd {
    padding: var(--padding-light);
    text-align: left;
    max-width: 200px;
    min-height: 50px;
    min-width: 100px;
}

/*スマホ用*/
@media screen and (max-width: 1099px) {
    .sideBar {
        display: none;
    }
    .mainWithSideBarR {
        gap: 0;
    }
    .primaryP {
        line-height: 28px;
    }

}

@media screen and (max-width: 599px) {
    .primarySection {
        padding: var(--padding-middle);
    }

    .secondarySection {
        padding: var(--padding-middle);
    }

    .sectionWrapper {
        width: 90%;
    }

    .tabContainerSize {
        width: 100%;
    }

    .tabItem {
        font-size: var(--font-size-light);
        width: 80px;
    }

    .tabItemInverse {
        font-size: var(--font-size-light);
    }

    .tabContainer {
        gap: var(--gap-too-light);
    }
}