.appList {
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap-middle);
    justify-content: center;
} 

.appList > li {
    background-color: var(--secondary-bg-color);
    border-radius: var(--border-radius-light);
    box-shadow: var(--primary-box-shadow);
    display: flex;
    flex-flow: column;
    gap: var(--gap-light);
    width: 320px;
    padding: var(--padding-middle);
}

.appList > li:hover {
    transform: scale(1.01);
}

.thumbnail {
    border-radius: var(--border-radius-middle);
    height: 60px;
    margin: 0 auto;
    max-width: 100%;
}

.publishedAt {
    color: var(--tertiary-text-color);
    font-size: var(--font-size-light);
}

.appBaseInfo {
    display: flex;
    gap: var(--gap-middle);
}

.strong {
    font-weight: bold;
    font-size: var(--font-size-middle);
}

.appEdit {
    display: flex;
    align-items: baseline;
    gap: 64px;
    margin-left: var(--margin-light);
    margin-top: var(--margin-too-light);
}

.appName {
    font-weight: bold;
    font-size: var(--font-size-middle);
}

.appName:hover {
    color: var(--primary-button-hover-color)
}

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap-light);
    margin: var(--margin-too-light) 0 var(--margin-too-light) 0;
}

.card {
    border-bottom: solid  0.5px var(--border-color);
}

.contentsPreview {
    line-height: var(--line-height-middle);
}

.imageName {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: var(--margin-too-light);
}

.imageName a, .imageName p {
    flex: 1;
    text-align: center;
}
