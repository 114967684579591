.chatContainer {
    background-color: var(--secondary-bg-color); 
    padding: var(--padding-middle);
    border-radius: var(--border-radius-light);
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 136, 204, 0.2);
}

.chatArea {
    height: 220px;
    padding-left: var(--padding-middle);
    padding-right: var(--padding-middle);
    overflow: scroll;
}

.chatArea p {
    border-radius: var(--border-radius-middle);
    font-size: var(--font-size-light);
    padding: var(--padding-light);
    width: fit-content;
}

.chatArea p:nth-child(n+2) {
    margin-top: 4px;
}

.chatArea p.chatBot {
    background-color: #ebebeb;
}

.chatArea p.chatUser {
    background-color: var(--primary-accent-color);
    color: var(--secondary-text-color);
    margin-left: auto;
}

.chatContainer > form{
    align-items: center;
    display: flex;
    gap: 10px;
    margin-top: 20px;
    justify-content: space-between;
    height: 40px;
}

.chatContainer > form input {
    border-radius: 20px;
    border: solid 1px var(--border-color);
    height: 40px;
    padding: 0 20px;
    flex-grow: 1;
}

.chatButton {
    border-radius: 20px;
    height: 40px;
    padding: 4px 0px;
    width: 70px;
}

.postList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-top: 20px;
    font-size: 20px;
} 

.postList > li {
    display: flex;
    flex-flow: column;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 10px 20px 20px 20px;
    transition: transform .2s;
    box-shadow: 0 0 10px rgba(0, 136, 204, 0.2);
}

.postList > li:hover {
    transform: scale(1.01);
}

.small {
    margin-right: 12px;
    font-size: 0.75rem;
}

.tag {
    padding: 4px 8px;
    font-size: 0.75rem;
    border-radius: 12px;
    border: solid 1px #e0e0e0;
    margin-right: 6px;
}

.appNameContainer {
    display: flex;
    align-items: center;
    margin: 14px 0;
}

.appName {
    font-weight: bold;
    font-size: 1.17rem;
}

.postList li:hover .appName a {
    color: var(--primary-accent-color);
}

.logo {
    width: 28px;
    height: 28px;
    margin-right: 10px;
}

.shortDescription {
    font-size: 15px;
}

.fadeIn {
    opacity: 0;
    transition: opacity 2s ease-in-out;
}
  
.fadeInVisible {
    opacity: 1;
}

.contributors {
    display: flex;
    flex-wrap: nowrap;
    gap: var(--gap-light);
    overflow-x: scroll;
    width: 200px;
    margin-top: 6px;
}

.contributors img {
    display: block;
}

@media (max-width: 768px) {
    .postList {
        display: contents;
        font-size: 20px;
    }
    .postList > li {
        background-color: #ffffff;
        border-radius: 10px;
        padding: 8px 16px 14px 16px;
        margin: 12px 20px 14px 20px;
        transition: transform 0.3s ease;
        box-shadow: 0 0 10px rgba(0, 136, 204, 0.2);
    }
    .History {
        margin: 0 20px;
    }
    .unity {
        padding-bottom: 20px;
        border-bottom: dashed thin;
    }
} 