.privacyWrapper > h1 {
    margin-bottom: var(--margin-middle);
}

.privacyWrapper > h2:nth-of-type(n+1) {
    margin-top: var(--margin-too-heavy);
}

.privacyWrapper > h2 {
    width: 100%;
}

.privacyWrapper > p {
    margin-top: var(--margin-middle);
    width: 100%;
}