.pWrapper {
    position: relative;
}

.pInner {
    background-color: var(--primary-bg-color);
    border-radius: var(--border-radius-light);
    display: block;
    height: fit-content;
    min-height: 100px;
    padding: var(--padding-middle);
    position: relative;
    width: 100%;
}

.selectTypeOfP {
    background-color: white;
    border: solid 1px var(--border-color);
    border-radius: var(--border-radius-light) 0 0 0;
    font-size: var(--font-size-light);
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
}

.buttonInput {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.img {
    display: block;
    width: 100%;
}

.errorOnLoadImageText {
    color: var(--tertiary-text-color);
    font-weight: bold;
}

.typeP {
    font-size: var(--font-size-light);
}

.typeH {
    font-size: var(--font-size-middle);
    font-weight: bold;
}

.typeSH {
    font-size: var(--font-size-middle);
    font-weight: 600;
}

/* table */
.createTable {
    align-items: center;
    display: flex;
    gap: var(--gap-light);
    justify-content: center;
    vertical-align: middle;
}

.inputRow, .inputColumn {
    width: 60px;
}

.thOptionsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    margin: 0 auto;
    margin-top: var(--margin-light);
}

.thOptionsWrapper input {
    margin-right: var(--margin-too-light);
}

.tableTemplate td, .tableTemplate th {
    border: 1px solid black;
    height: 14px;
    width: 20px;
}

.tableTemplate tr:nth-of-type(odd) {
    background-color: white;
}

.tableTemplate tr:nth-of-type(even) {
    background-color: #ededed;
}

.tableTemplate th {
    background-color: var(--primary-accent-color);
}

.tableTemplate {
    border-collapse: collapse;
    margin: 0 auto;
    margin-top: var(--margin-light);
}

.table {
    margin: 0 auto;
}

.table td, .table th {
    border-left: 1px solid var(--border-color);
}

.table .trHeader input {
    color: white;
}

.table th input {
    font-weight: bold;
}

.buttonAddP {
    background-color: white;
    border: solid 1px var(--border-color);
    border-radius: 50%;
    height: 20px;
    left: 50%;
    position: absolute;
    top: 100%;
    transform: translate(-50%, -50%);
    width: 20px;
}

.buttonAddP:hover {
    color: var(--primary-accent-color);
}

.buttonDelete {
    height: 20px;
    position: absolute;
    right: 0;
    top:0;
    width: 20px;
}

.buttonDelete:hover {
    color: var(--primary-accent-color);
}

.moveButtonWrapper {
    display: flex;
    flex-flow: column;
    gap: var(--gap-light);
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.moveButtonWrapper > button {
    width: 20px;
}

.moveButtonWrapper > button:hover {
    color: var(--primary-accent-color);
}

.restrictLength {
    font-size: var(--font-size-light);
    height: 20px;
    line-height: 20px;
    position: absolute;
    right: 2px;
    bottom: 2px;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
  
.modalImage {
    max-width: 80%;
    max-height: 80%;
}