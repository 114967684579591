.siteFooter {
    margin-top: var(--margin-heavy);
    margin-bottom: var(--margin-middle);
}

.copyRight {
    height: 24px;
    line-height: 24px;
    margin-top: var(--margin-middle);
    text-align: center;
}

.footerLinksWrapper {
    border-bottom: solid 1px var(--border-color);
    display: flex;
    gap: var(--gap-middle);
    justify-content: center;
    padding-bottom: var(--padding-middle);
    margin-left: auto;
    margin-right: auto;
    max-width: 1000px;
    width: fit-content;
}

.footerLinksWrapper div {
    width: 140px;
}

.footerLinksWrapper p {
    font-weight: bold;
}

.footerLinksWrapper ul {
    display: flex;
    flex-direction: column;
    gap: var(--gap-light);
    margin-top: var(--margin-light);
}