.editorHistoryButtonWrapper {
    align-items: center;
    display: flex;
    gap: var(--gap-middle);
    margin-bottom: var(--margin-middle);
    max-width: 800px;
    width: 100%;
}

.editorHistoryButtonWrapper button svg{
    display: block;
    width: 20px;
    height: 20px;
}

.invalidButton svg path {
    color: var(--invalid-button-color);
}

.validButton:hover svg path {
    color: var(--primary-accent-color);
}

.articleArea {
    max-width: 800px;
    width: 100%;
}

.articleArea > .attributeWrapper:nth-of-type(n+2) {
    margin-top: var(--margin-middle);
}

.articleArea > h2 {
    margin-bottom: var(--margin-middle);
}

.articleArea > h2:nth-of-type(n+2) {
    margin-top: var(--margin-heavy);
}

.attributeWrapper > label {
    vertical-align: middle;
}

.attributeWrapper > ul {
    background-color: var(--primary-bg-color);
    border-radius: var(--border-radius-light);
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap-light);
    margin-top: var(--margin-light);
    margin-bottom: var(--margin-light);
    padding: var(--padding-light);
}

.attributeWrapper li {
    display: block;
}

.attributeWrapper li .tagIcon, .attributeWrapper li .tagText, .attributeWrapper li .tag {
    background-color: white;
}

.buttonsWrapper {
    display: flex;
    justify-content: center;
    gap: var(--gap-heavy);
    margin-top: var(--margin-heavy);
}

.buttonsWrapper button {
    padding-left: var(--padding-light);
    padding-right: var(--padding-light);
}

.tag {
    display: block;
}

.inputWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap-middle);
}

.descriptionWrapper {
    flex-direction: column;
    gap: 0;
}

.descriptionWrapper textarea {
    height: 100px;
    padding: var(--padding-middle);
    width: 100%;
}

.descriptionWrapper p {
    font-size: var(--font-size-light);
    text-align: right;
}

.restrictLength {
    font-size: var(--font-size-light);
    text-align: right;
    margin-top: 4px;
}

.restrictArrayLength {
    font-size: var(--font-size-light);
    text-align: right;
    margin-top: 20px;
}

.articleWrapper {
    display: flex;
    flex-flow: column;
    gap: var(--gap-middle)
}

.removeButton {
    background: none;
    border: none;
    font-size: 1em; 
    color: #000;
    cursor: pointer;
}


.tabContainer {
    display: flex;
    flex-wrap: nowrap;
    gap: var(--gap-light);
    justify-content: center;
    margin-bottom: var(--margin-middle);
}
  
.tabItem {
    border: solid 1px var(--primary-accent-color);
    border-bottom: 0;
    border-radius: var(--border-radius-light) var(--border-radius-light) 0 0;
    cursor: pointer;
    font-weight: bold;
    text-align: center;
    height: 40px;
    line-height: 40px;
    width: 100px;
}
  
.tabItemActive {
    background-color: var(--primary-accent-color);
    border: none;
    color: white;
    font-weight: bold;
}

.thumbnailWrapper {
    background-color: var(--primary-bg-color);
    border-radius: var(--border-radius-light);
    max-width: 320px;
    min-height: 100px;
    position: relative;
    width: 100%;
}

.thumbnailWrapper > img {
    display: block;
    width: 100%;
}

.thumbnailWrapper > button {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.requiredMark {
    background-color: var(--primary-caution-color);
    border-radius: var(--border-radius-too-light);
    color: white;
    font-size: var(--font-size-too-light);
    font-weight: bold;
    margin-left: var(--margin-light);
    padding: 3px;
    vertical-align: middle;
}

.anyMark {
    background-color: #5e5b5be5;
    border-radius: var(--border-radius-too-light);
    color: white;
    font-size: var(--font-size-too-light);
    font-weight: bold;
    margin-left: var(--margin-light);
    padding: 3px;
    vertical-align: middle;
}

.link {
    color: black;
    font-weight: bold;
    font-size: var(--font-size-light);
    line-height: var(--line-height-middle);
}

.link:hover {
    color: var(--tertiary-text-color); 
}

.buttonAddP {
    background-color: white;
    border: solid 1px var(--border-color);
    border-radius: 50%;
    height: 20px;
    left: 50%;
    position: relative;
    margin-top: 4%;
    transform: translate(-50%, -50%);
    width: 20px;
}

.buttonAddP:hover {
    color: var(--primary-accent-color);
}

.inputNameWrapper {
    display: flex;
    flex-direction: column;
    gap: var(--gap-light);
    width: fit-content;
}

.predictServiceNamesWrapper {
    background-color: white;
    border: solid 1px var(--border-color);
    border-radius: var(--border-radius-light);
    display: flex;
    flex-direction: column;
    gap: var(--gap-light);
    padding: var(--padding-light);
}

.predictServiceNamesWrapper p {
    color: var(--tertiary-text-color);
    font-size: var(--font-size-light);
    font-weight: bold;
}

.predictName:hover {
    cursor: pointer;
    color: var(--primary-accent-color);
}

.articleWrapperWrite {
    margin-top: var(--margin-middle);
}

.isServiceNamesWrapper {
    background-color: white;
    border: solid 1px var(--border-color);
    border-radius: var(--border-radius-light);
    display: flex;
    flex-direction: column;
    gap: var(--gap-light);
    padding: var(--padding-light);
}

.link {
    color: black;
    font-weight: bold;
    font-size: var(--font-size-light);
    line-height: var(--line-height-middle);
}

.link:hover {
    color: var(--tertiary-text-color); 
}