.userInfo {
    padding: 16px 10%;
    text-align: left;
    background-color: #ffffff;
    width: 100%;
    display: flex;
    align-items: center;
}
  
.icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: var(--margin-middle);
}
  
.userName {
    font-size: var(--font-size-heavy);
    font-weight: bold;
}
  
.userProfile {
    font-size: var(--font-size-light);
}

.moreButton {
    margin-top: var(--margin-middle);
}

.tabIcon {
    margin-right: var(--margin-too-light);
}

.activeIcon > path {
    color: #fff;
}

.userRanking {
    margin-left: var(--margin-heavy);
}

.viewCount {
    display: none;
}

@media screen and (max-width: 599px) {
    .tabIcon {
        margin-right: 4px;
        font-size: 10px;
    }

    .iconSize {
        font-size: 10px;
    }

    .userRanking {
        display: none;
    }

    .viewCount {
        display: contents;
    }

    .viewCount > span {
        margin-left: 2px;
        margin-right: 4px;
    }

    .viewAndRanking {
        display: flex;
        flex-direction: column;
        margin-top: var(--margin-light);
    }

    .viewAndRanking > div {
        display: flex;
        align-items: baseline;
    }
}