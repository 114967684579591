.error {
    color: red;
    font-size: 12px;
    margin: 5px 0;
}

.modal {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.formWrapper {
    background-color: white;
    padding: 30px;
    border-radius: var(--border-radius-light);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 34%;
}

.deleteConfirmBox {
    background: #fff;
    padding: 20px;
    text-align: center;
}

.formWrapper input {
    width: 100%;
}

.formWrapper textarea {
    width: 100%;
    height: 76px;
}

.formWrapper textarea:nth-of-type(2) {
    height: 114px;
}

.UseCaseFormWrapper {
    background-color: white;
    padding: 40px;
    border-radius: var(--border-radius-light);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;
    height: 70%;
    width: 36%;
}

.UseCaseFormWrappe input {
    width: 100%;
}

.UseCaseFormWrapper textarea {
    width: 100%;
    height: 64%;
}

.imageUpload {
    position: relative;
    display: inline-block; 
 }

.imageInput {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.fileName {
    display: flex;
    gap: 4px;
}

.fileName p {
    font-size: 12px;
}

.icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.iconWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    color: gray;
}

.deleteConfirmMessage {
    color: red;
    font-weight: bold;
    margin-bottom: 20px;
}

.searchContainer {
    position: absolute;
    margin-left: 17%;
    top: 0;
    bottom: 0;
    line-height: 80px;
}

.searchContainer input {
    width: 150px;
    padding: 6px;
    color: #fff;
    background-color: #000000;
    border: 2px solid #000;
}

.verificationMessage {
    color: #008000; 
    position: absolute;
    top: 100%; 
    width: 100%; 
    text-align: center; 
    padding: 2px 0; 
}

.previewModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }

.previewContent {
    max-height: 80vh;  
    overflow-y: auto;  
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
}
  
.closeButton {
    position: fixed;
    top: 20px; 
    right: 20px;
    border: none;  
    padding: 10px 20px; 
    border-radius: 5px;  
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);  
    cursor: pointer; 
}
  
.closeButton:hover {
    background-color: #c0bbbb;  
}

.google {
    display: flex;
    gap: 16px;
}

.googleIcon {
    background-color: white;
    border-radius: 12px;
    height: 24px;
    padding: 4px;
    width: 24px;
}

.googleButton {
    display: flex;
    gap: 6px;
    align-items: center;
}

.strong {
    font-weight: bold;
}

.deleteButton {
    display: flex;
    align-items: center;
    gap: 20px;
}  

@media (max-width: 768px) {
    .formWrapper {
        background-color: white;
        padding: 30px;
        border-radius: var(--border-radius-light);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        width: 80%;
    }
}