/*.chatContainer {
    background-color: var(--secondary-bg-color); 
    padding: var(--padding-middle);
    border-radius: var(--border-radius-light);
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 136, 204, 0.2);
}

.chatArea {
    height: 220px;
    padding-left: var(--padding-middle);
    padding-right: var(--padding-middle);
    overflow: scroll;
}

.chatArea p {
    border-radius: var(--border-radius-middle);
    font-size: var(--font-size-light);
    padding: var(--padding-light);
    width: fit-content;
}

.chatArea p:nth-child(n+2) {
    margin-top: 4px;
}

.chatArea p.chatBot {
    background-color: #ebebeb;
}

.chatArea p.chatUser {
    background-color: var(--primary-accent-color);
    color: var(--secondary-text-color);
    margin-left: auto;
}

.chatContainer > form{
    align-items: center;
    display: flex;
    gap: 10px;
    margin-top: 20px;
    justify-content: space-between;
    height: 40px;
}

.chatContainer > form input {
    border-radius: 20px;
    border: solid 1px var(--border-color);
    height: 40px;
    padding: 0 20px;
    flex-grow: 1;
}

.chatButton {
    border-radius: 20px;
    height: 40px;
    padding: 4px 0px;
    width: 70px;
}*/
.whatIsTechBaseWrapper {
    display: flex;
    flex-direction: column;
    gap: var(--gap-middle);
}

.whatIsTechBaseHeader {
    font-size: var(--font-size-heavy);
    font-weight: bold;
}

.whatIsTechBaseButton {
    text-align: center;
    width: 60%;
}

.searchForm {
    display: flex;
    gap: var(--gap-middle);
    width: 70%;
}

.searchForm input {
    flex-grow: 1;
}

.trendsContainer {
    display: flex;
    gap: var(--gap-too-heavy);
}

.trendListTitle {
    font-size: var(--font-size-heaavy);
    font-weight: bold;
}

.trendList {
    display: flex;
    flex-direction: column;
    gap: var(--gap-light);
    margin-top: var(--margin-light);
}

.trendList > li {
    display: flex;
    gap: var(--gap-light);
}

.trendIndex {
    background-color: var(--primary-accent-color);
    border-radius: 24px;
    color: white;
    font-weight: bold;
    height: 24px;
    line-height: 24px;
    position: relative;
    text-align: center;
    width: 24px;
}

.trendList > li:nth-child(-n+3) .trendIndex::after {
    background-color: transparent;
    border: 1px solid var(--primary-accent-color);
    border-radius: 28px;
    content: '';
    height: 28px;
    left: -3px;
    position: absolute;
    top: -3px;
    width: 28px;
}

.trendLink {
    flex: 1;
}

.trendLink:hover {
    color: var(--primary-accent-color);
}

@media (max-width: 768px) {
    .searchForm {
        display: flex;
        gap: var(--gap-middle);
        width: 100%;
    }
}

@media screen and (max-width: 599px) {
    .whatIsTechBaseButton {
        width: 80%;
    }
}
