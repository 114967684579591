.appList {
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap-middle);
    justify-content: center;
} 

.appList > li {
    background-color: var(--secondary-bg-color);
    border-radius: var(--border-radius-light);
    box-shadow: var(--primary-box-shadow);
    display: flex;
    flex-flow: column;
    gap: var(--gap-too-light);
    width: 320px;
    padding: var(--padding-middle);
}

.appList > li:hover {
    transform: scale(1.01);
}

.thumbnail {
    background-color: var(--primary-accent-color);
    border-radius: var(--border-radius-middle);
    display: block;
    height: 80px;
    margin: 0 auto;
    max-width: 100%;
}

.publishedAt {
    color: var(--tertiary-text-color);
    font-size: var(--font-size-too-light);
    line-height: var(--font-size-too-light);
}

.appBaseInfo {
    display: flex;
    gap: var(--gap-middle);
    align-items: center
}

.appBaseInfo span{
    font-size: var(--font-size-light);
}

.count {
    font-size: var(--font-size-light);
}

.appName {
    font-weight: bold;
    font-size: var(--font-size-heavy);
}

.appList li:hover .appName a {
    color: var(--primary-accent-color);
}

.tags {
    font-size: var(--font-size-light);
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap-light);
}

.tags a:hover {
    background-color: var(--primary-accent-color);
    color: var(--secondary-text-color);
}

.tags a:hover path {
    color: var(--secondary-text-color);
}

.edit {
    font-weight: bold;
    border: solid 1px;
    border-radius: var(--border-radius-tag);
    padding: var(--padding-tag-middle);
    color: #fff;
    background-color: var(--primary-accent-color);
}

.new {
    font-weight: bold;
    border: solid 1px;
    border-radius: var(--border-radius-tag);
    padding: var(--padding-tag-middle);
    color: #fff;
    background-color: var(--tag-new-color);
}

.shortDescription {
    margin-top: var(--margin-too-light);
    font-size: var(--font-size-light);
}