.tagLogoWrapper {
    align-items: center;
    background-color: var(--primary-accent-color);
    border-radius: var(--border-radius-light);
    display: flex;
    gap: var(--gap-too-light);
    justify-content: center;
    padding: var(--padding-middle);
    width: 80%;
}

.tagLogoWrapper span {
    color: white;
    font-size: var(--font-size-too-heavy);
    font-weight: bold;
}

.tagLogoWrapper svg {
    height: 24px;
    width: 24px;
}

.tagLogoWrapper svg path {
    color: white;
}

.addNewAppButtonWrapper {
    display: flex;
    flex-direction: column;
    gap: var(--gap-light);
    align-items: center;
    margin-top: var(--margin-middle);
}

.addNewAppButtonWrapper p {
    color: var(--tertiary-text-color);
    font-weight: bold;
    text-align: center;
}

.addNewAppButtonWrapper a {
    display: block;
    width: fit-content;
}

.loadMoreButton {
    margin-top: var(--margin-light);
}

@media (max-width: 768px) {
    .tagLogoWrapper {
        width: 100%;
    }
}