.tagActive {
    background-color: var(--primary-accent-color);
    color: var(--secondary-text-color);
}

.sortWrapper {
    max-width: 500px;
}

.sortOptions {
    display: flex;
    gap: var(--gap-light);
    margin-top: var(--margin-light);
}

.sortOptions li {
    cursor: pointer;
}

.loadMoreButton {
    margin-top: var(--margin-middle);
}