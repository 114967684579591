.wrapper {
    margin-bottom: 20vh;
    text-align: center;
}

.subject {
    font-size: var(--font-size-too-heavy);
    margin-top: var(--margin-heavy);
}

.verifyEmail {
    margin: var(--margin-middle) 0 var(--margin-heavy) 0;
}

.strong {
    font-weight: bold;
}

.formWrapper {
   margin: 0 auto;
}