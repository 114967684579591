.loader,
.loader:before,
.loader:after {
  background: var(--primary-accent-color);
  animation: load1 1s infinite ease-in-out;
  width: 10px;
  height: 30px;
}

.loader {
  color: var(--primary-accent-color);
  margin: 0 auto;
  position: relative;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}

.loader:before {
  left: -15px;
  animation-delay: -0.32s;
}

.loader:after {
  left: 15px;
}

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 30px;
  }
  40% {
    box-shadow: 0 -10px;
    height: 40px;
  }
}

.loaderWrapper {
    height: 30px;
    margin: 30px 0;
}
