:root {
    --header-height: 80px;
}

header {
    background-color: white;
    border-bottom: solid 1px var(--border-color);
    height: var(--header-height);
}

.headerInner {
    display: flex;
    height: 100%; 
    justify-content: space-between;
    margin: 0 auto;
    width: 80%;
}

header .logo {
    line-height: var(--header-height);
}

.logo img {
    height: 36px;
    vertical-align: middle;
    width: 36px;
}

.logo span{
    color: var(--primary-text-color);
    font-size: 20px;
    font-weight: 900;
    vertical-align: middle;
    margin-left: var(--margin-light);
}

.configButton {
    display: flex;
    gap: var(--gap-heavy);
}

.config {
    align-items: center;
    cursor: pointer;
    display: flex;
    position: relative;
    top: 3.5px;
}

.config svg > path { color: #595656 }

.rotate {
    animation: spin 0.2s linear;
}

@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(45deg);}
}

.buttonContainer {
    align-items: center;
    display: flex;
    gap: 0 8px;
    height: var(--header-height);
    line-height: var(--header-height);
}

.settingButtonContainer {
    position: relative;
}

.settingButton img {
    height: 24px;
    width: 24px;
    vertical-align: middle;
    margin-top: 4px;
}

.dropDown {
    background-color: white;
    border-radius: var(--border-radius-light);
    box-shadow: var(--primary-box-shadow);
    position: absolute;
    right: 0;
    top: calc(var(--header-height) - 12px);
    width: 240px;
    z-index: 10;
}

.dropDown li {
    cursor: pointer;
    line-height: 32px;
}

.dropDown li:hover {
    color: var(--primary-accent-color);
}

.dropDown a {
    display: block;
}

.dropDown a:hover {
    color: var(--primary-accent-color);
}

.dropDown ul {
    padding: 10px 20px;
}

.dropDown ul:nth-child(n+2) {
    border-top: solid 1px var(--border-color);
}

@media screen and (max-width: 599px) {
    .headerInner {
        width: 90%;
    }

    .headerButton {
        font-size: var(--font-size-light);
    }

    .logo span {
        font-size: var(--font-size-middle);
    }
}