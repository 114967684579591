.primarySideBarNav {
    background-color: var(--secondary-bg-color);
    border-radius: var(--border-radius-light);
    padding: var(--padding-middle);
    width: 100%;
}

.secondarySideBarNav {
    background-color: transparent;
    border-radius: var(--border-radius-light);
    padding: var(--padding-middle);
    width: 100%;
}

.stickyNav {
    position: sticky;
    top: 0;
}

/*articleNav*/
.articleNavHeader {
    font-weight: bold;
}

.navNest {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: var(--gap-light);
}

.navH2List {
    display: flex;
    flex-direction: column;
    gap: var(--gap-middle);
    margin-top: var(--margin-middle);
}

.navH2List > li {
    display: flex;
    gap: var(--gap-light);
}

.navH2Index {
    background-color: var(--primary-accent-color);
    border-radius: 12px;
    color: white;
    font-weight: bold;
    height: 24px;
    line-height: 24px;
    text-align: center;
    width: 24px;
}

.navH2 {
    flex: 1;
    font-weight: bold;
}

.navH3List {
    display: flex;
    flex-direction: column;
    gap: var(--gap-light);
}

.navH3 {
    font-size: var(--font-size-light);
}

/*twitterCard*/
.twitterCard {
    display: flex;
    gap: var(--gap-light);
}

.twitterIcon {
    width: 32px;
    height: 32px;
}

.twitterDescription {
    font-size: var(--font-size-light);
}

.twitterCard a {
    color: var(--primary-button-color);
}

.twitterCard a:hover {
    color: var( --primary-button-hover-color);
}

/*updateCard*/
.updateCard {
    display: flex;
    flex-direction: column;
    gap: var(--gap-light);
}

.updateHeader {
    font-weight: bold;
}

.updateDescription {
    font-size: var(--font-size-light);
}

.updateLink {
    text-align: center;
    color: var(--primary-button-color);
}

.updateLink:hover {
    color: var( --primary-button-hover-color);
}

/*tagNav*/
.tagCard {
    display: flex;
    flex-direction: column;
    gap: var(--gap-middle);
}

.tagHeader {
    font-weight: bold;
}

.tagList {
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap-light);
}

.tagList a {
    cursor: pointer;
}

.tagList a:hover {
    background-color: var(--primary-accent-color);
    color: var(--secondary-text-color);
}

.tagList a:hover path {
    color: var(--secondary-text-color);
}

/*userEditRankingNav*/
.userEditRankingHeader {
    font-weight: bold;
}

.userList {
    display: flex;
    flex-direction: column;
    gap: var(--gap-light);
    margin-top: var(--margin-middle);
}

.userList > li {
    display: flex;
    flex-direction: column;
}

.userWrapper {
    align-items: center;
    display: flex;
    flex: 1;
    gap: var(--gap-light);
    height: fit-content;
}

.userWrapper img {
    background-color: white;
}

.rankingIndex {
    background-color: var(--primary-accent-color);
    border-radius: var(--border-radius-too-light);
    color: white;
    display: inline-block;
    font-weight: bold;
    height: 24px;
    line-height: 24px;
    position: relative;
    text-align: center;
    width: 24px;
}

.userList > li:nth-child(-n+3) .rankingIndex::after {
    background-color: transparent;
    border: 1px solid var(--primary-accent-color);
    border-radius: calc(var(--border-radius-too-light) + 1px);
    content: '';
    height: 28px;
    left: -3px;
    position: absolute;
    top: -3px;
    width: 28px;
}

.userName {
    flex: 1;
    font-size: var(--font-size-light);
    overflow-x: scroll;
    /*スクロールバー非表示（IE・Edge）*/
    -ms-overflow-style: none;
    /*スクロールバー非表示（Firefox）*/
    scrollbar-width: none;
    white-space: nowrap;
}

/*スクロールバー非表示（Chrome・Safari）*/
.userName::-webkit-scrollbar {
    display:none;
}

.userName:hover {
    color: var(--primary-accent-color);
}

.userIconWrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.userCount {
    font-size: var(--font-size-light);
    font-weight: bold;
}

.userEdit {
    font-size: var(--font-size-too-light);
}