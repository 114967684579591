.appThumbnail {
    background-color: var(--primary-accent-color);
    border-radius: var(--border-radius-middle);
    display: block;
    order: 1;
}

.articleArea {
    max-width: 800px;
    width: 100%;
}

.articleArea > h2:nth-of-type(n+2) {
    margin-top: var(--margin-too-heavy);
}

.articleArea > h3 {
    margin-top: var(--margin-heavy);
    white-space: pre-wrap;
}

.articleArea > p, .articleArea > div {
    margin-top: var(--margin-middle);
    white-space: pre-wrap;
}

/*.articleArea > img {
    background-color: var(--primary-bg-color);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius-middle);
    display: block;
    margin-top: var(--margin-middle);
    width: 100%;
}*/

.appInfoWrapper {
    align-items: center;
    display: flex;
    gap: var(--gap-middle);
    width: 100%;
}

.appInfoWrapper > * {
    width: 100px;
    flex: 1;
}

.appInfoWrapper .tabContainer {
    margin-top: var(--margin-middle);
}

.longDescription, .toSiteButton {
    margin-top: var(--margin-middle);
}

.toSiteButton {
    max-width: 400px;
    text-align: center;
    width: 100%;
}

.tabContainerArticle {
    margin-bottom: var(--margin-middle);
}

.infoTab {
    display: flex;
    flex-direction: column;
    gap: var(--gap-middle);
    height: 200px;
    margin-top: var(--margin-middle);
    overflow-y: scroll;
    /*スクロールバー非表示（IE・Edge）*/
    -ms-overflow-style: none;
    /*スクロールバー非表示（Firefox）*/
    scrollbar-width: none;
}

/*スクロールバー非表示（Chrome・Safari）*/
.infoTab::-webkit-scrollbar {
    display:none;
}

.iconsUl {
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap-light);
}

.iconWrapper {
    align-items: center;
    display: flex;
    gap: var(--gap-light);
}

.iconWrapper > svg {
    width: 20px;
    height: 20px;
}

.iconWrapper > svg > path {
    color: var(--primary-accent-color);
}

.titleWrapper {
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    padding-bottom: var(--padding-too-light);
}

.titleWrapper h1 {
    font-size: var(--font-size-too-heavy);
    font-weight: bold;
}

.viewWrapper {
    margin-top: var(--margin-light);
}

.viewCount {
    font-size: var(--font-size-middle);
    font-weight: bold;
}

.viewsText {
    font-size: var(--font-size-light);
    margin-left: var(--margin-light);
}

.evaluateWrapper {
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    gap: 10px;
    margin-top: var(--margin-light);
    padding-bottom: calc(3px + var(--margin-light)); /*badの位置3pxだけ足す*/
}

.evaluateWrapper span {
    font-weight: bold;
    height: 20px;
    line-height: 20px;
}

.evaluateWrapper button {
    height: 20px;
    position: relative;
    width: 20px;
}

.evaluateWrapper button:hover svg path {
    color: var(--primary-accent-color);
}

.evaluateWrapper .setButton svg path {
    color: var(--primary-accent-color);
}

.evaluateWrapper button svg {
    display: block;
    height: 20px;
    position: absolute;
    width: 20px;
}

.thumbUp {
    top: 0;
}

.favorite {
    top: 0;
}

.favoriteButton {
    margin-left: var(--margin-middle);
}

.editButton {
    border-radius: 50%;
    display: block;
    height: 24px;
    width: 24px;
}

.editButton:hover {
    background-color: var(--hover-foreground-color);
}

.editButton svg {
    height: 16px;
    vertical-align: middle;
    width: 16px;
}

.settingButtonContainer {
    position: relative;
    display: flex;
    gap: var(--gap-middle);
    align-items: center;
}

.dropDown {
    background-color: white;
    border-radius: var(--border-radius-light);
    box-shadow: var(--primary-box-shadow);
    position: absolute;
    right: 0;
    top: calc(24px + 12px);
    width: 160px;
    z-index: 10;
}

.dropDown li {
    cursor: pointer;
    font-size: 14px;
    line-height: 32px;
}

.dropDown li:hover {
    color: var(--primary-accent-color);
}

.dropDown a {
    display: block;
}

.dropDown a:hover {
    color: var(--primary-accent-color);
}

.dropDown ul {
    padding: 10px 20px;
}

.title {
    text-align: center;
    margin-top: var(--margin-middle);
    font-size: var(--font-size-too-heavy);
}

.head {
    margin-top: var(--margin-middle);
    white-space: pre-wrap;
}

.para {
    margin-top: var(--margin-middle);
    white-space: pre-wrap;
}

.image {
    display: block;
    margin-top: var(--margin-middle);
    width: 100%;
}

.index {
    margin-right: var(--margin-middle);
    font-size: var(--font-size-middle);
}

.index:hover {
    color: var(--primary-button-hover-color);
}

.indexMore {
    margin-right: var(--margin-middle);
    font-size: var(--font-size-middle);
}

.indexMore:hover {
    color: var(--primary-button-hover-color);
}

.selectButton {
    margin: 0 var(--margin-heavy) 0 var(--margin-light);
}

.setting {
    margin-bottom: var(--margin-middle);
    padding-bottom: var(--padding-too-light);
    border-bottom: 1px solid var(--border-color);
}

.evaluateBottomWrapper {
    border: none;
    padding-bottom: var(--padding-light);
}

.user {
    display: flex;
    gap: var(--gap-light);
    align-items: flex-end;
    margin-bottom: var(--margin-middle);
}

.userIcon {
    border-radius: 50%;
    height: 36px;
    width: 36px;
}

.userName {
    font-size: var(--font-size-middle);
    font-weight: bold;
}

.userName:hover {
    color: var(--primary-accent-color);
}

.deleteButton {
    display: flex;
    justify-content: center;
    margin-bottom: var(--margin-light);
}

.tag:hover {
    background-color: var(--primary-accent-color);
    color: var(--secondary-text-color);
}

.tag:hover path {
    color: var(--secondary-text-color);
}

.img {
    background-color: var(--primary-bg-color);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius-middle);
    display: block;
    margin: 0 auto;
    margin-top: var(--margin-middle);
    max-width: 600px;
    width: 100%;
}

@media screen and (max-width: 599px) {
    .appThumbnail {
        order: 0;
    }

    .appInfoWrapper {
        flex-direction: column;
        gap: var(--gap-middle);
    }

    .appInfoWrapper > * {
        flex: initial;
        width: 100%;
    }
    .index {
        margin-right: var(--margin-light);
        font-size: var(--font-size-middle);
    }
    .indexMore {
        margin-right: var(--margin-middle);
        font-size: var(--font-size-light);
    }
    .selectButton {
        margin: 0 var(--margin-light) 0 var(--margin-light);
    }
}