.userButton {
    cursor: default;
    background-color: var(--white-button-color);
    border-radius: var(--border-radius-light);
    color: var(--primary-text-color);
    font-weight: bold;
    height: var(--primary-input-light-height);
    padding-left: var(--padding-too-light);
    padding-right: var(--padding-too-light);
}

.userButton:hover {
    background-color: var(--white-button-color);
}

.submitButton {
    cursor: default;
    background-color: var(--primary-button-color);
    border-radius: var(--border-radius-light);
    color: var(--secondary-text-color);
    display: inline-block;
    font-weight: bold;
    height: var(--primary-input-light-height);
    padding-left: var(--padding-too-light);
    padding-right: var(--padding-too-light);
}

.submitButton:hover {
    background-color: var(--primary-button-color);
}

.mainWrapper > h1 {
    margin-bottom: var(--margin-middle);
}

.mainWrapper > h2:nth-of-type(n+2) {
    margin-top: var(--margin-too-heavy);
}

.mainWrapper > h2 {
    width: 100%;
}

.mainWrapper > p {
    margin-top: var(--margin-middle);
    width: 100%;
}

.textArea {
    background-color: var(--primary-bg-color);
    border-radius: var(--border-radius-light);
    display: block;
    height: fit-content;
    min-height: 100px;
    overflow: hidden;
    padding: var(--padding-middle);
    width: 100%;
}

.subject {
    font-weight: bold;
}

.supplement {
    font-size: var(--font-size-light);
}

.tagPrimaryColor {
    align-items: center;
    border: 1px solid var(--primary-accent-color);
    border-radius: 14px;
    color: var(--primary-accent-color);
    font-size: var(--font-size-light);
    font-weight: bold;
    gap: var(--gap-too-light);
    height: 28px;
    padding: 0 var(--padding-light);
    margin-right: var(--padding-tag-light);
}

.tabItem {
    position: relative;
    border: solid 1px var(--primary-accent-color);
    border-radius: var(--border-radius-light) var(--border-radius-light) 0 0;
    cursor: pointer;
    font-weight: bold;
    text-align: center;
    height: 40px;
    line-height: 40px;
    width: 90px;
    border-bottom: none;
}