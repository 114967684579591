.logo {
    color: var(--tertiary-text-color);
    font-size: 64px;
    font-weight: bold;
}

.messageWrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
}