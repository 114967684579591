.searchForm {
    display: flex;
    gap: var(--gap-middle);
    width: 70%;
}

.searchForm input {
    flex-grow: 1;
}

.moveButtonWrapper {
    display: flex;
    gap: var(--gap-middle);
    margin-bottom: var(--margin-middle);
}

.addNewAppButtonWrapper {
    display: flex;
    flex-direction: column;
    gap: var(--gap-light);
    align-items: center;
    margin-top: var(--margin-middle);
}

.addNewAppButtonWrapper p {
    color: var(--tertiary-text-color);
    font-weight: bold;
    text-align: center;
}

.addNewAppButtonWrapper a {
    display: block;
    width: fit-content;
}

@media (max-width: 768px) {
    .searchForm {
        display: flex;
        gap: var(--gap-middle);
        width: 100%;
    }
}